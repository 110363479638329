import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet tot ell de color marró daurat, amb un capell de 4-5 cm de diàmetre, amb un mamelló ben visible cobert de fibril·les o esquames, que destaca perquè aquestes darreres es fan patents i molt atapeïdes al disc central, essent més espaiades cap al marge. Les làmines de davall són marrons per la caiguada de les espores amb l’aresta blanca. El peu és llarg, ondulat i amb restes de vel i les espores també marrons en massa, el·lipsoïdals, de 10-13 x 6-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      